<template>
  <div class="contatain">
    <div class="tab">
      <!-- tab栏 -->
      <div
        class="tab-item"
        v-for="(item, index) in tabList"
        :key="`a${index}`"
        :class="curTab === item.id ? 'tab-item-active' : ''"
        @click="curTab = item.id"
      >
        {{ item.name }}
      </div>
    </div>
    <filter-item v-show="curTab === 1" @dealFilter="dealFilter" />
    <filter-item v-show="curTab === 2" @dealFilter="dealFilter" />
    <div class="content-wrap">
      <el-card class="row2-card" v-loading="loading">
        <div v-if="curTab === 1" class="flex-row flex-ac">
          <div class="title">数据表</div>
          <div class="table-remark">
            注：因微信数据延迟，昨天的部分数据可能未更新。
          </div>
        </div>
        <div v-else class="flex-row flex-as">
          <div class="title">数据表</div>
          <div class="table-remark flex-row">
            <span>注：</span>
            <div>
              1.因微信数据延迟，昨天的部分数据可能未更新；<br />
              2.本页数据从2021年2月13日开始记录。
            </div>
          </div>
        </div>
        <div v-if="curTab === 1" class="flex-row flex-as">
          <div class="computed-title">人流趋势</div>
          <el-table
            border
            :data="tableData1"
            :header-cell-style="thStyle"
            :cell-style="cellStyle"
            style="width: auto; flex: 0 1 auto"
          >
            <el-table-column prop="title" label="" width="80">
            </el-table-column>
            <el-table-column label="访问人次" width="130">
              <template slot-scope="scope"> {{ scope.row.num1 }}次 </template>
            </el-table-column>
            <el-table-column prop="num2" label="访问用户数" width="130">
              <template slot-scope="scope"> {{ scope.row.num2 }}人 </template>
            </el-table-column>
            <el-table-column prop="num4" label="累计访问用户数" width="130">
              <template slot-scope="scope"> {{ scope.row.num3 }}人 </template>
            </el-table-column>
            <el-table-column prop="num3" label="新增用户数" width="130">
              <template slot-scope="scope"> {{ scope.row.num4 }}人 </template>
            </el-table-column>
            <el-table-column prop="num4" label="领券用户数" width="130">
              <template slot-scope="scope"> {{ scope.row.num5 }}人 </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="curTab === 2" class="flex-row flex-as">
          <div class="computed-title">性别占比</div>
          <el-table
            border
            :data="tableData2.list1"
            :cell-style="cellStyle"
            :header-cell-style="thStyle"
            style="width: auto; flex: 0 1 auto"
          >
            <el-table-column prop="title" label="" width="80">
            </el-table-column>
            <el-table-column label="男性" width="130">
              <template slot-scope="scope">
                {{ scope.row.num1 + scope.row.unit }}
              </template>
            </el-table-column>
            <el-table-column label="女性" width="130">
              <template slot-scope="scope">
                {{ scope.row.num2 + scope.row.unit }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div v-if="curTab === 2" class="flex-row flex-as">
          <div class="computed-title">年龄占比</div>
          <el-table
            border
            :data="tableData2.list2"
            :header-cell-style="thStyle"
            :cell-style="cellStyle"
            style="width: auto; flex: 0 1 auto"
          >
            <el-table-column prop="title" label="" width="80">
            </el-table-column>
            <el-table-column label="≤17岁" width="130">
              <template slot-scope="scope">
                {{ scope.row.num1 + scope.row.unit }}
              </template>
            </el-table-column>
            <el-table-column label="18-24岁" width="130">
              <template slot-scope="scope">
                {{ scope.row.num2 + scope.row.unit }}
              </template>
            </el-table-column>
            <el-table-column label="25-29岁" width="130">
              <template slot-scope="scope">
                {{ scope.row.num3 + scope.row.unit }}
              </template>
            </el-table-column>
            <el-table-column label="30-39岁" width="130">
              <template slot-scope="scope">
                {{ scope.row.num4 + scope.row.unit }}
              </template>
            </el-table-column>
            <el-table-column label="≥40岁" width="130">
              <template slot-scope="scope">
                {{ scope.row.num5 + scope.row.unit }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="flex-row flex-as">
          <div class="title">数据图</div>
          <div class="flex-column">
            <el-checkbox-group
              v-if="curTab === 1"
              v-model="checkList1"
              style="width: 750px"
            >
              <el-checkbox
                v-for="(item, index) in nameList1"
                :key="index"
                style="width: 100px"
                :label="index + 1 + ''"
                :disabled="isPieType1"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
            <el-checkbox-group
              v-if="curTab === 2"
              v-model="checkList2"
              style="width: 750px"
            >
              <el-checkbox
                v-for="(item, index) in nameList2.slice(0, 2)"
                :key="index"
                style="width: 100px"
                :label="index + 1 + ''"
                :disabled="isPieType2"
                >{{ item }}</el-checkbox
              ><br />
              <el-checkbox
                v-for="(item, index) in nameList2.slice(2, 7)"
                :key="index + 2"
                style="width: 100px"
                :label="index + 3 + ''"
                :disabled="isPieType2"
                >{{ item }}</el-checkbox
              >
            </el-checkbox-group>
            <el-button
              v-if="curTab === 1"
              class="m-t-10"
              type="primary"
              size="mini"
              @click="showChart"
              :disabled="isPieType1"
              >展示数据</el-button
            >
            <el-button
              v-if="curTab === 2"
              class="m-t-10"
              type="primary"
              size="mini"
              @click="showChart"
              :disabled="isPieType2"
              >展示数据</el-button
            >
            <echart-item
              v-show="curTab === 1"
              :data="chartData1"
              :lineList="lineList1"
              :nameList="nameList1"
              :pieData1="usePieData1"
              pieDataName1="用户数（单位：人）"
              @pieType="pieType"
              dataType="userUse"
            />
            <echart-item
              v-show="curTab === 2"
              :data="chartData2"
              :lineList="lineList2"
              :nameList="nameList2"
              :pieData1="drawPieData1"
              :pieData2="drawPieData2"
              pieDataName1="性别（单位：人）"
              pieDataName2="年龄段（单位：人）"
              @pieType="pieType"
              dataType="userDraw"
            />
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import FilterItem from "./common/component/FilterItem.vue";
import EchartItem from "./common/component/EchartItem.vue";
import { timestampToDate, debounce } from "@tools";
export default {
  components: {
    FilterItem,
    EchartItem,
  },
  data() {
    return {
      tabList: [
        { name: "使用分析", id: 1 },
        { name: "用户画像", id: 2 },
      ],
      checkList1: ["1"], // 左tab的选项数组
      checkList2: ["1"], // 右tab的选项数组
      curTab: 1,
      tableData1: [], // 左tab的所有表格数据
      tableData2: {}, // 右tab的所有表格数据
      loading: false,
      tableFilter1: {
        day: 30,
        ignoreFlag: true,
        platform: "",
        endTime: timestampToDate(new Date().getTime(), false),
        startTime: timestampToDate(
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          false
        ),
      }, // 左tab的顶部筛选条件
      tableFilter2: {
        ignoreFlag: true,
        platform: "",
        endTime: timestampToDate(new Date().getTime(), false),
        startTime: timestampToDate(
          new Date().getTime() - 3600 * 1000 * 24 * 30,
          false
        ),
      }, // 右tab的顶部筛选条件
      nameList1: [
        "访问人次",
        "访问用户数",
        "累计访问用户数",
        "新增用户数",
        "领券用户数",
      ], // 左tab的选项名称数组，以下同理
      nameList2: [
        "男性",
        "女性",
        "≤17岁",
        "18-24岁",
        "25-29岁",
        "30-39岁",
        "≥40岁",
      ],
      chartData1: [], // 左tab的图表数据
      chartData2: [],
      usePieData1: [], // 左tab饼状数据1
      usePieData2: [], // 左tab饼状数据2
      drawPieData1: [],
      drawPieData2: [],
      lineList1: ["访问人次"], // 需要折线的选项
      lineList2: ["男性", "女性"], // 需要折线的选项
      isPieType1: false,
      isPieType2: false,
      pieCheckList1: ["2", "3", "4"], // 饼状图需要勾选死的选项
      pieCheckList2: ["1", "2", "3", "4", "5", "6", "7"], // 饼状图需要勾选死的选项
    };
  },
  async created() {
    this.getUserDataList();
  },
  methods: {
    getUserDataList: debounce(async function () {
      this.loading = true;
      const params = this[`tableFilter${this.curTab}`],
        type = this.curTab === 1 ? "Use" : "Draw";
        params.ignoreMinute = localStorage.ignoreMinute
      const data = await this.$api.chiguaData[`getUser${type}DataList`](
        params
      ).finally(() => {
        this.loading = false;
      });
      //   处理数据
      if (this.curTab === 1) {
        const list = [],
          obj1 = { title: "总数" },
          obj2 = { title: "日平均值" };
        data.forEach((item) => {
          let key = item.key;
          obj1[`num${key}`] = item.sum;
          obj2[`num${key}`] = item.dayAvg;
        });
        list.push(obj1, obj2);
        this.tableData1 = list;
      } else {
        let list1 = [],
          list2 = [],
          obj1 = { title: "总数", unit: "人" },
          obj2 = { title: "日平均值", unit: "%" };
        data.genderList.forEach((item) => {
          let key = item.key;
          obj1[`num${key}`] = item.value;
          obj2[`num${key}`] = item.percentage;
        });
        list1.push(obj1, obj2);
        obj1 = { title: "总数", unit: "人" };
        obj2 = { title: "日平均值", unit: "%" };
        data.ageList.forEach((item) => {
          let key = item.key;
          obj1[`num${key}`] = item.value;
          obj2[`num${key}`] = item.percentage;
        });
        list2.push(obj1, obj2);
        this.tableData2 = { list1, list2 };
      }
      this.getPieData(data);
      this.getUserDataChart();
    }, 300),
    async getUserDataChart() {
      this.loading = true;
      const params = this[`tableFilter${this.curTab}`],
        type = this.curTab === 1 ? "Use" : "Draw",
        checkList = this[`isPieType${this.curTab}`] // 防止饼图状态时，筛选，然后影响到后面切其他图
          ? "pieCheckList"
          : "checkList";
      params.dataFlagList = this[`${checkList}${this.curTab}`]
        .sort()
        .toString();
        params.ignoreMinute = localStorage.ignoreMinute
      const data = await this.$api.chiguaData[`getUser${type}DataChart`](
        params
      ).finally(() => {
        this.loading = false;
      });
      this[`chartData${this.curTab}`] = data;
    },
    dealFilter(params, diffTime) {
      this.curTab === 1 && (params.day = diffTime);
      this[`tableFilter${this.curTab}`] = params;
      this.getUserDataList();
    },
    showChart() {
      const tab = this.curTab,
        params = this[`tableFilter${tab}`];
      params.dataFlagList = this[`checkList${tab}`].sort().toString();
      this.getUserDataChart(params);
    },
    getPieData(data) {
      // 处理饼图的数据
      if (this.curTab === 1) {
        this.usePieData1.splice(
          0,
          3,
          { name: "访问用户数", value: data[1].sum },
          { name: "新增用户数", value: data[2].sum },
          { name: "领券用户数", value: data[3].sum }
        );
      } else {
        this.drawPieData1.splice(
          0,
          2,
          { name: "男性", value: data.genderList[0].value },
          { name: "女性", value: data.genderList[1].value }
        );
        this.drawPieData2.splice(
          0,
          5,
          { name: "≤17岁", value: data.ageList[0].value },
          { name: "18-24岁", value: data.ageList[1].value },
          { name: "25-29岁", value: data.ageList[2].value },
          { name: "30-39岁", value: data.ageList[3].value },
          { name: "≥40岁", value: data.ageList[4].value }
        );
      }
    },
    pieType(isPie) {
      // 切换到饼图时的回调，变灰
      const cur = this.curTab === 1 ? 1 : 2,
        isPieType = this[`isPieType${cur}`];
      if ((!isPieType && isPie) || (isPieType && !isPie)) {
        [this[`checkList${cur}`], this[`pieCheckList${cur}`]] = [
          this[`pieCheckList${cur}`],
          this[`checkList${cur}`],
        ];
      }
      this[`isPieType${cur}`] = isPie;
    },
    openDialog(ref) {
      this.$refs[ref].showDialog = true;
    },
    thStyle() {
      return {
        background: "rgba(197, 192, 255, 0.1)",
        color: "#000",
        textAlign: "center",
      };
    },
    cellStyle() {
      return {
        fontWeight: "500",
        color: "#000",
        textAlign: "center",
      };
    },
  },
  watch: {
    curTab() {
      this.getUserDataList();
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "./common/style/dataForm.scss";
@import "./common/style/dataTable.scss";
.item-date {
  text-align: left;
}
</style>